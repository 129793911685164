/**
 * 动画对象
 */
export interface IAnimation {
  mode: 'fade' | 'translate' | 'rotate' | 'scale' | 'none';
  type?: string;
  duration: number;
  delay: number;
  ease: string;
}

/**
 * 图层元素
 */
export interface IElement {
  index: number;
  type: string;
  name: string;
  show: boolean;
  transform: {
    x: number;
    y: number;
    width: number;
    height: number;
    skewX: number;
    skewY: number;
    rotate: number;
    shadow: any;
  };
  animation: {
    in: IAnimation;
    out: IAnimation;
    transformOrigin: string;
  };
  params: any;
}

/**
 * 图层模板控制参数对象
 */
export interface ITemplateControlParam {
  elementId: string;
  type: string;
  name: string;
  params: {
    name: string;
    type: string;
    value: any;
    path: string;
    [key: string]: any;
  }[];
}

/**
 * 图层模板数据
 */
export interface ILayerTemplate {
  id: string;
  name: string;
  type: string;
  show: boolean;
  transform: {
    x: number;
    y: number;
    width: number;
    height: number;
    skewX: number;
    skewY: number;
    rotate: number;
  };
  elements: {
    [key: string]: IElement;
  };
  controlParams: ITemplateControlParam[];
}

/**
 * 拖拽缩放数据
 */
export interface IResizeData {
  id?: string;
  width: number;
  height: number;
  left: number;
  top: number;
}

/**
 * composition 使用数据对象
 */
export interface ICompositionData {
  id: string;
  name: string;
  description: string;
  layerTemplates: ILayerTemplate[];
  coverUrl: string;
  tags: string[];
  deviceType: string;
  resolution: string;
  templateId?: string;
}

/**
 * 发布状态
 */
export enum PublishStatus {
  NoPublish = 1,
  Auditing = 2,
  Pass = 3,
  Reject = 4,
  Offline = 5,
}

/**
 * 发布状态对应文案
 */
export const PublishStatusText = new Map([
  [PublishStatus.NoPublish, '未发布'],
  [PublishStatus.Auditing, '审核中'],
  [PublishStatus.Pass, '审核通过'],
  [PublishStatus.Reject, '驳回'],
  [PublishStatus.Offline, '已下线'],
]);

/**
 * 模板表单信息
 */
export interface ITemplateFormData {
  name: string;
  coverUrl: string;
  tags: string[];
}

/**
 * 模板类型
 */
export enum Privacy {
  Private = 1, // 私有的
  Public = 2, // 公共的
}

/**
 * 模板列表数据项 item
 */
export interface ITemplateItem {
  createdAt?: string;
  resolution?: string;
  description?: string;
  layerTemplates?: string | any[];
  name?: string;
  coverUrl: string;
  templateId?: string;
  privacy?: Privacy;
  tags: string[];
  checked?: boolean;
  status?: number;
  deviceType?: string;
}

/**
 * 模板标签数据项
 */
export interface ITagOption {
  description: string;
  id: string;
  name: string;
}

/**
 * 模板详情
 */
export interface ITemplateDetail {
  id: string;
  name: string;
  description: string;
  layerTemplates: string;
  coverUrl: string;
  tags: string[];
  deviceType: string;
  resolution: string;
}

/**
 * 图层树选中对象
 */
export interface IActiveSelect {
  id: string;
  layerId: string;
  type: string;
}

/**
 *  元素类型
 */
export enum ElementType {
  /** 图层 */
  Layer = 'layer',
  /** 方块 */
  Rect = 'rect',
  /** 文本 */
  Text = 'text',
  /** 图片 */
  Image = 'image',
  /** 倒计时 */
  Time = 'time',
  /** 多场次比分 */
  Score = 'score',
  /** 滚动字幕 */
  Marquee = 'marquee',
}

/** time 类型 */
export enum TimeType {
  /** count up 正计时 */
  Up = 'up',
  /** count down 倒计时 */
  Down = 'down',
  /** to time of day 离结束时间还有多久 */
  End = 'end',
  /** current time 当前时间 */
  Current = 'current',
}

/** processBar 类型 */
export enum ProcessType {
  /** 普通类型1 */
  Filled = 'filled',
  /** 蓝色环 */
  Gradient = 'gradient',
  /** 双环 */
  Alternating = 'alternating',
}

/** 当前时间格式 */
export const currentFormatList = [
  {
    label: 'hh:mm:ss a',
    value: 'hh:mm:ss a',
  },
  {
    label: 'hh:mm a',
    value: 'hh:mm a',
  },
  {
    label: 'HH:mm:ss',
    value: 'HH:mm:ss',
  },
  {
    label: 'HH:mm',
    value: 'HH:mm',
  },
];
